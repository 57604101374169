<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <component
                :is="getFormieComponent('file')"
                v-bind="getField(field).getInputBindings()"
                :accept="field.accept"
                :data-pristine-allowed-file-extensions="field.accept"
                :data-pristine-allowed-mbs="allowedMbs"
                class="file-input"
                @change="uploadFile"
            />

            <BaseIcon name="attach-file" />
        </component>
    </div>
</template>

<script setup>
const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
    modelValue: {
        type: Object,
        required: true,
    },
});

// Props to refs
const { field } = toRefs(props);

const allowedMbs = computed(() => {
    if (field.value?.sizeMinLimit && field.value?.sizeLimit) {
        return `${field.value.sizeMinLimit},${field.value.sizeLimit}`;
    } else if (field.value?.sizeLimit) {
        return `0,${field.value?.sizeLimit}`;
    }
    return '';
});

// Update parent v-model on input
const emit = defineEmits(['update:modelValue']);

const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

const uploadFile = async(e) => {
    const base64 = await fileToBase64(e.target.files[0]);

    // See https://verbb.io/craft-plugins/formie/docs/v1/developers/graphql#file-upload-field
    const uploadData = [{
        fileData: base64,
        filename: e.target.files[0].name,
    }];

    emit('update:modelValue', uploadData);
};

</script>
